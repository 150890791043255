<template>
  <div>
    <div class="center">
      <div class="changetypebox">
        <div class="typeitem" @click="showsearch">
          全部
          <div class="triangle-down"></div>
        </div>
        <div class="typeitem">
          价格
          <div class="triangle-down"></div>
        </div>
        <div class="typeitem">可兑换</div>
        <div class="searchlistbox" v-show="issearch">
          <div
            class="searchitem"
            v-for="item in searchList"
            :key="item.id"
            :class="{ acsearchitem: item.id == searchindex }"
            @click="searchclick(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="searchbox">
        <input
          type="text"
          placeholder="请输入想要搜索的商品"
          v-model="searchvalue"
        />
        <div class="searchenter" @click="searchvuleclick">
          <img src="../../assets/newImages/search-icon.png" alt="" />
        </div>
      </div>

      <div class="listbox">
        <div
          class="goodsitem"
          v-for="item in goodsvalue"
          :key="item.id"
          @click.stop="tocheck(item)"
        >
          <div class="cintbox"><Cint :price="item.mall_bean" /></div>
          <div class="goodsimg">
            <img v-lazy="item.cover" alt="" />
          </div>
          <div class="goodsname">
            {{ item.name }}
          </div>
          <div class="goodsdur">
            {{ item.dura_alias }}
          </div>

          <div class="ControlBox" v-show="chekindex.includes(item)">
            <div class="footerbox">
              <div class="lbox" @click.stop="subclick(item)"></div>
              <div class="numbox">
                {{ getCartItemById(item.id)?.quantity || 0 }}
              </div>
              <div class="rbox" @click.stop="addclick(item)"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <Pagination
        v-show="total > 0"
        :total="total"
        :page.sync="page_num"
        :limit.sync="page_size"
        @pagination="handleCurrentChange"
      /> -->
    </div>
    <div class="caritem">
        <div class="caricon">
            <img src="../../assets/newImages/caritem-icon.png" alt="">
        </div>
      {{ totalPrice.toFixed(2) }}
    </div>

    <Tabbar />
  </div>
</template>

<script>
import { GetShop, Shop_Buy, PersonalInfo, luckyType } from "@/network/api.js";
export default {
  data() {
    return {
      searchList: [],
      searchindex: 0,
      issearch: false,
      searchvalue: "",

      goodsvalue: [], //商品列表

      chekindex: [], //选中物品

      cartItems: [], // 购物车，每个商品项包含 id, name, price, quantity, checked
      newItemQuantity: 1,
    };
  },
  computed: {
    totalPrice() {
      return this.cartItems.reduce((total, item) => {
        return total + item.price * item.quantity;
      }, 0);
    },
  },
  created() {
    this.Gettype();
    this.getgoodsdata();
  },
  methods: {
    subclick(id) {
      this.cartItems = this.cartItems
        .map((item) => {
          if (item.id == id.id) {
            if (item.quantity > 1) {
              item.quantity -= 1;
            } else {
              // 当数量为1时，移除该商品并取消选中
              this.tocheck(id);
              return null;
            }
          }
          return item;
        })
        .filter((item) => item !== null);
    },
    addclick(v) {
      const product = this.goodsvalue.find((p) => p.id === v.id);
      if (product) {
        let cartItem = this.cartItems.find((item) => item.id === product.id);
        if (!cartItem) {
          this.cartItems.push({
            id: product.id,
            name: product.name,
            price: product.mall_bean,
            quantity: Number(this.newItemQuantity),
            img: product.cover,
          });
        } else {
          cartItem.quantity += Number(this.newItemQuantity);
        }
        this.newItemQuantity = 1;
      }
    },

    tocheck(item) {
      const isChecked = this.chekindex.includes(item);
      if (isChecked) {
        // 如果已选中，则取消选中
        this.chekindex = this.chekindex.filter((i) => i !== item);
        // 从购物车中减少数量，如果数量为1则移除
        this.cartItems = this.cartItems
          .map((cartItem) => {
            if (cartItem.id === item.id) {
              if (cartItem.quantity) {
                // 如果有数量，则移除该商品
                return null;
              }
            }
            return cartItem;
          })
          .filter((cartItem) => cartItem !== null);
      } else {
        // 如果未选中，则选中并添加到购物车
        this.chekindex.push(item);
        // 检查购物车中是否已存在该商品，如果不存在则添加，如果存在则增加数量
        const cartItem = this.cartItems.find(
          (cartItem) => cartItem.id === item.id
        );
        if (!cartItem) {
          this.cartItems.push({
            id: item.id,
            name: item.name,
            price: item.mall_bean,
            quantity: 1, // 初始数量为1
            // 可以选择性地添加其他属性，如 img
            img: item.cover, // 如果需要的话
          });
        } else {
          // 用户每次点击都是增加1个数量
          cartItem.quantity += 1;
        }
      }
    },
    getCartItemById(id) {
      return this.cartItems.find((item) => item.id === id);
    },

    async getgoodsdata() {
      const res = await GetShop();
      this.goodsvalue = res.data.data.data;
    },
    // 搜索商品
    searchvuleclick() {},
    showsearch() {
      this.issearch = !this.issearch;
    },
    // 分类切换
    searchclick(v) {
      this.searchindex = v;
    },
    // 获得装备分类数据
    Gettype() {
      luckyType().then((res) => {
        let data = res.data.data;
        data.splice(6, 2);
        this.searchList = [
          {
            name: "全部",
            id: 0,
          },
        ];
        this.searchList.push(...data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  width: 3.35rem;
  margin: 0 auto;
  .changetypebox {
    width: 100%;
    height: 0.22rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.12rem 0;
    position: relative;
    .typeitem {
      width: 1.07rem;
      height: 0.22rem;
      @include bgImg("price-change-bg");
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
      position: relative;
      .triangle-down {
        position: absolute;
        right: 0.1rem;
        width: 0;
        height: 0;
        border-left: 0.06rem solid transparent; /* 左边透明 */
        border-right: 0.06rem solid transparent; /* 右边透明 */
        border-top: 0.06rem solid #ffffff; /* 顶部颜色 */
      }
    }
    .searchlistbox {
      position: absolute;
      top: 0.25rem;
      width: 100%;
      height: 0.88rem;
      background: #1d1300;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      z-index: 3;
      .searchitem {
        padding: 0.05rem 0.18rem;
        background: rgba(63, 53, 33, 0.5);
        margin: 0.05rem;
        font-weight: 400;
        font-size: 0.12rem;
        color: #a89265;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .acsearchitem {
        background: rgba(255, 213, 129, 0.5);
        color: #ffffff;
      }
    }
  }
  .searchbox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 0.22rem;
    input {
      width: 2.9rem;
      height: 100%;
      border: 0.01rem solid #817043;
      text-decoration: none;
      background: rgba(42, 27, 0, 0.6);
      text-indent: 0.28rem;
      color: #a4a4a4;
    }
    .searchenter {
      width: 0.5rem;
      height: 0.22rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../../assets/newImages/search-btn-bg.png") no-repeat;
      background-size: 100% 100%;
      img {
        width: 0.1rem;
        height: 0.1rem;
      }
    }
  }
  .listbox {
    margin-top: 0.07rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    .goodsitem {
      width: 1.07rem;
      height: 1.07rem;
      background: #1d1300;
      border: 0.01rem solid #69552d;
      margin-top: 0.07rem;
      position: relative;
      .cintbox {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 0.02rem;
      }
      .goodsimg {
        width: 0.8rem;
        height: 0.6rem;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .goodsname {
        font-weight: 400;
        font-size: 0.1rem;
        color: #ffffff;
        text-align: center;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 隐藏超出部分 */
        text-overflow: ellipsis; /* 显示省略号 */
        padding: 0 0.05rem;
      }
      .goodsdur {
        font-weight: 400;
        font-size: 0.1rem;
        color: #ffffff;
        text-align: center;
        margin-top: 0.05rem;
      }
      .ControlBox {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1.07rem;
        height: 1.07rem;
        @include bgImg("battle-box-choosed");
        .footerbox {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 0.25rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.02rem;
          .lbox {
            width: 0.2rem;
            height: 0.2rem;
            @include bgImg("down-icon");
          }
          .rbox {
            width: 0.2rem;
            height: 0.2rem;
            @include bgImg("up-icon");
          }
          .numbox {
            font-weight: 500;
            font-size: 0.12rem;
            color: #040404;
          }
        }
      }
    }
  }
}
.caritem {
  position: fixed;
  bottom: 0.7rem;
  width: 100%;
  height: 0.5rem;
  display: flex;
  align-items: center;
  background: #4f4424;
  padding: 0 0.2rem;
}
</style>
